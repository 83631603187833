<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.promote')" />
    </ion-header>
    <ion-content>
      <BaseContent class="promote">
        <h3 class="promote__title">💎 {{ $t('promote.title') }} 💎</h3>

        <h6 v-text="$t('promote.promoted-token-section-title')" />
        <ul>
          <li v-text="$t('promote.promoted-token-section-offer-1')" />
          <li v-text="$t('promote.promoted-token-section-offer-2')" />
          <li>
            <strong v-text="$t('promote.promoted-token-section-offer-3')" />
          </li>
          <li v-text="$t('promote.promoted-token-section-offer-4')" />
        </ul>

        <h6 v-text="$t('promote.promoted-token-twitter-title')" />
        <ul>
          <li v-text="$t('promote.promoted-token-twitter-offer-1')" />
          <li v-text="$t('promote.promoted-token-twitter-offer-2')" />
        </ul>

        <div class="promote__informations">
          <h6 v-text="$t('promote.informations-message')" />
          <ul>
            <li>
              <a href="mailto:contact@tokenvote.co">contact@tokenvote.co</a>
            </li>
            <li>
              Twitter <a href="https://twitter.com/TokenVoteCO">@TokenVoteCO</a>
            </li>
          </ul>
        </div>
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import { IonPage, IonContent, IonHeader } from '@ionic/vue';

export default defineComponent({
  name: 'Promote',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
  },
});
</script>

<style lang="scss" scoped>
.promote {
  &__title {
    text-align: center;
  }
  &__informations {
    a {
      color: inherit;
      font-weight: var(--fw-bold);
      text-decoration: underline;
    }
  }
}
</style>
